import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchFences(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          //.get('/apps/user/users', { params: queryParams })
          .get(`/api/v1/VirtualFence/search?Search=${queryParams.q}&Page=${queryParams.page}&PerPage=${queryParams.perPage}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchFence(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          //.get(`/apps/user/users/${id}`)
          .get(`/api/v1/VirtualFence?id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addFence(ctx, fenceData) {
      return new Promise((resolve, reject) => {
        
        let fence = fenceData.fenceData
        axios
          //.post('/apps/customer/customers', { customer: customerData })
          .post('/api/v1/VirtualFence',  fence)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateFence(ctx, fenceData) {
      return new Promise((resolve, reject) => {
        
        let fence = fenceData.fenceData
        axios
          //.post('/apps/customer/customers', { customer: customerData })
          .put('/api/v1/VirtualFence',  fence)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteFence(ctx, {id}) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/VirtualFence?Id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
  },
    fetchCustomers(ctx, queryParams) {

      return new Promise((resolve, reject) => {
          
          axios
              //.get('/apps/asset/assets', { params: queryParams })
              .get(`/api/v1/Customer/search?Search=${queryParams.q}&Page=1&PerPage=5`)
              //.get(`/api/v1/Asset/search?Page=${queryParams.page}&PerPage=${queryParams.perPage}`)
              .then(response => resolve(response))
              .catch(error => reject(error))
      })
  },
  fetchCustomer(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          //.get(`/apps/user/users/${id}`)
          .get(`/api/v1/Customer?id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
